import React from 'react';
import { Link } from 'gatsby';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';

const StyledCard = styled(Card)`
  &:hover {
    transform: scale(1.01);
    transition: transform 0.15s ease-out;
  }

  a {
    color: ${(props) => props.theme.textColor};
  }
`;

const ServiceCard = ({
  featuredImage,
  title,
  excerpt,
  slug,
  categories = [],
  className = '',
  ...props
}) => (
  <StyledCard className="mb-4 overflow-hidden position-relative shadow">
    <Row noGutters>
      {featuredImage && (
        <Col xs={3} style={{ height: '160px' }}>
          <Card.Img src={featuredImage} />
        </Col>
      )}
      <Col xs={9}>
        <Card.Body>
          {title && <Card.Title className="mb-0">{title}</Card.Title>}
          <p className="small text-muted">
            {categories && categories.map((cat) => cat.category).join(', ')}
          </p>
          {excerpt && <Card.Text>{excerpt}</Card.Text>}
          <Link to={slug} className="stretched-link">
            Read more
          </Link>
        </Card.Body>
      </Col>
    </Row>
  </StyledCard>
);

export default ServiceCard;
