import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ServiceCard from './ServiceCard';

class ServiceSection extends React.Component {
  static defaultProps = {
    services: [],
    title: '',
    limit: 12,
    showLoadMore: true,
    loadMoreTitle: 'Load More',
    perPageLimit: 12,
  };

  state = {
    limit: this.props.limit,
  };

  increaseLimit = () =>
    this.setState((prevState) => ({
      limit: prevState.limit + this.props.perPageLimit,
    }));

  render() {
    const { services, title, showLoadMore, loadMoreTitle } = this.props;
    const { limit } = this.state;
    const visibleServices = services.slice(0, limit || services.length);

    return (
      <Container>
        <Row>
          {title && (
            <Col xs={12}>
              <h2>{title}</h2>
            </Col>
          )}
          <Col xs={12}>
            {!!visibleServices.length && (
              <div>
                {visibleServices.map((service, index) => (
                  <ServiceCard key={service.title + index} {...service} />
                ))}
              </div>
            )}
          </Col>
          {showLoadMore && visibleServices.length < services.length && (
            <Col xs={12} className="text-center">
              <Button variant="link" onClick={this.increaseLimit}>
                {loadMoreTitle}
              </Button>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}

export default ServiceSection;
