import React from 'react';
import { graphql } from 'gatsby';
import { Location } from '@reach/router';
import qs from 'qs';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import PageHeader from '../components/PageHeader';
import ServiceSection from '../components/ServiceSection';
import ServiceCategoriesNav from '../components/ServiceCategoriesNav';
import Layout from '../components/Layout';
import ReadyToGet from '../components/ReadyToGet';

/**
 * Filter services by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {services} object
 */
export const byDate = (services) => {
  const now = Date.now();
  return services.filter((service) => Date.parse(service.date) <= now);
};

/**
 * filter services by category.
 *
 * @param {services} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (services, title, contentType) => {
  const isCategory = contentType === 'serviceCategories';
  const byCategory = (service) =>
    service.categories && service.categories.filter((cat) => cat.category === title).length;
  return isCategory ? services.filter(byCategory) : services;
};

const StyledMain = styled.main`
  .card {
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.textColor};
    min-height: 132px;
    min-width: 160px;
  }

  .card:hover {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .nav-link {
    color: ${(props) => props.theme.textColor} !important;
  }

  .form-control {
    background-color: ${(props) => props.theme.backgroundColor};
    color: ${(props) => props.theme.textColor} !important;
  }
`;

// Export Template for use in CMS preview
export const ServicesIndexTemplate = ({
  title,
  subtitle,
  services = [],
  serviceCategories = [],
  enableSearch = true,
  contentType,
}) => (
  <Location>
    {({ location }) => {
      let filteredServices =
        services && !!services.length ? byCategory(byDate(services), title, contentType) : [];

      let queryObj = location.search.replace('?', '');
      queryObj = qs.parse(queryObj);

      if (enableSearch && queryObj.s) {
        const searchTerm = queryObj.s.toLowerCase();
        filteredServices = filteredServices.filter((service) =>
          service.frontmatter.title.toLowerCase().includes(searchTerm)
        );
      }

      return (
        <StyledMain className="Services">
          <PageHeader title={title} subtitle={subtitle} />

          {!!serviceCategories.length && (
            <Container className="mb-5">
              <Row>
                <ServiceCategoriesNav enableSearch categories={serviceCategories} />
              </Row>
            </Container>
          )}

          {!!services.length && (
            <Container>
              <Row>
                <ServiceSection services={filteredServices} />
              </Row>
            </Container>
          )}
          <ReadyToGet />
        </StyledMain>
      );
    }}
  </Location>
);

// Export Default ServicesIndex for front-end
const ServicesIndex = ({ data: { page, services, serviceCategories } }) => (
  <Layout meta={page.frontmatter.meta || false} title={page.frontmatter.title || false}>
    <ServicesIndexTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      services={services.edges.map((service) => ({
        ...service.node,
        ...service.node.frontmatter,
        ...service.node.fields,
      }))}
      serviceCategories={serviceCategories.edges.map((service) => ({
        ...service.node,
        ...service.node.frontmatter,
        ...service.node.fields,
      }))}
    />
  </Layout>
);

export default ServicesIndex;

export const pageQuery = graphql`
  ## Query for ServicesIndex data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query ServicesIndex($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      fields {
        contentType
      }
      frontmatter {
        title
        excerpt
        template
        subtitle
      }
    }

    services: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "services" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            excerpt
            categories {
              category
            }
            featuredImage
          }
        }
      }
    }
    serviceCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "serviceCategories" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
