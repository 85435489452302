import React from 'react';
import { Link } from 'gatsby';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import ServicesSearch from './ServicesSearch';

const ServiceCategoriesNav = ({ categories, enableSearch }) => (
  <Navbar className="w-100 flex-wrap">
    <Nav className="mr-auto flex-wrap">
      <Nav.Item>
        <Link className="nav-link" exact="true" to="/service-categories/">
          All
        </Link>
      </Nav.Item>
      {categories.map((category, index) => (
        <Nav.Item key={`nav-service-category-${index}`}>
          <Link exact="true" className="nav-link" to={category.slug}>
            {category.title}
          </Link>
        </Nav.Item>
      ))}
    </Nav>
    {enableSearch && <ServicesSearch />}
  </Navbar>
);

export default ServiceCategoriesNav;
